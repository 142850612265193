import React, { useEffect } from "react";

type PipeDriveFormProps = {
  formID: string;
};
export const PipeDriveForm: React.FC<PipeDriveFormProps> = (props) => {
  const { formID } = props;

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://webforms.pipedrive.com/f/loader";
    document.body.appendChild(script);
  }, []);

  return (
    <div
      className="pipedriveWebForms"
      data-pd-webforms={`https://webforms.pipedrive.com/f/${formID}`}
    />
  );
};
