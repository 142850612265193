/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { FC } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Language } from "~context/LanguageContext";

type Meta = {
  name: string;
  content: string;
};

export type MetaProps = {
  description?: string;
  lang?: Language;
  meta?: Meta[];
  title?: string;
  image?: string;
  isSeparateSite?: boolean;
  useTypeSquareFonts?: boolean;
};

const siteMetadata = {
  title: {
    ja: `株式会社Flatt Security | Flatt Security Inc.`,
    en: `Flatt Security Inc.`,
  },
  description: {
    ja: `株式会社Flatt Securityは、Webプロダクト開発組織に向けた各種セキュリティ事業を展開するサイバーセキュリティスタートアップです。`,
    en: `Flatt Security Inc. is a cyber security startup based in Tokyo, Japan. We provide security assessments and other cyber security services.`,
  },
};

export const SEO: FC<MetaProps> = (props) => {
  const {
    description,
    lang = "ja",
    meta = [],
    title = "",
    image,
    isSeparateSite,
  } = props;
  const { ogp } = useStaticQuery(graphql`
    query SEOTemplate {
      ogp: file(relativePath: { eq: "ogp.png" }) {
        publicURL
      }
    }
  `);

  const metaDescription = description || siteMetadata.description[lang];
  const metaTitle =
    title !== ""
      ? isSeparateSite
        ? `${title}`
        : `${title} | Flatt Security`
      : siteMetadata.title[lang];
  const metaImage = image || `https://flatt.tech${ogp.publicURL}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      defaultTitle={siteMetadata.title[lang]}
      title={metaTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: metaImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: `@flatt_security`,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        ...meta,
      ]}
    />
  );
};
