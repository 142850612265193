import React from "react";
import { SEO } from "~components/templates/seo";
import { PipeDriveForm } from "~components/utils/pipedrive-form";
import * as styles from "./index.module.scss";

const CONTACT_FORM_ID =
  "1DZdzDm9oaMJU0DQTdrJpxxqAasiGVfjgvZll4OgWP10wG8XZgsYZLTkNciJgS8h5";

const Component: React.FC = () => {
  return (
    <>
      <SEO meta={[{ name: "robots", content: "noindex" }]} />
      <div className={styles.Contact}>
        <PipeDriveForm formID={CONTACT_FORM_ID} />
      </div>
    </>
  );
};

export default Component;
